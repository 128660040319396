// DataTable.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import moment from "moment"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
// import { ConfirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';
// import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeicons/primeicons.css';
// import { verifyToken } from '../api/licenseAPI';
import { FilterMatchMode } from 'primereact/api';
import { Tag } from 'primereact/tag';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const [visible, setVisible] = useState(false);
  const [visibleResetMac, setVisibleResetMac] = useState(false);
  // const toast = useRef(null);
  const buttonEl = useRef(null);
  const [deleted, setDeleted] = useState(null);
  
  const getSeverity = (status) => {
    switch (status) {
        case 'Pro':
            return 'info';

        case 'Trial':
            return 'warning';
           
        case 'Aktif':
            return 'success';

        case 'Tidak Aktif':
            return 'danger';
            
        default:
              return 'info';

    }
  };

  const [filters, setFilters] = useState({
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    mac_address: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  useEffect(() => {
    RefreshData()
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/login');
    }
   
  }, [navigate])

  const RefreshData = () => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/LisenceList',
    })
    .then(
      response => {
        const resp = response.data.licenseList.map(e => ({
          id : e.id,
          softwareId : e.softwareId,
          email : e.email,
          mac_address : e.mac_address,
          expired : moment(e.expired).format('DD-MM-YYYY'),
          status : e.status ? 'Aktif' : 'Tidak Aktif',
          isTrial : e.isTrial ? 'Trial' : 'Pro',
          lastLogin : moment(e.lastLogin).format('DD-MM-YYYY'),
          totalItems : e.totalItems,
          totalCustomers : e.totalCustomers,
          totalTransactions : e.totalTransactions,
          lastTransactionValue : e.lastTransactionValue?.toLocaleString()
        }))
        setData(resp)
      }
    );
  }
  
  const handleEdit = (id) => {
    navigate('/licence/edit?id=' + id)
  };

  const handleDeleteMac = async(rowData) => {
    try {
      const dataPost = {
        softwareId : rowData.softwareId,
        mac_address : rowData.mac_address,
      }

      console.log(dataPost);
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/logout',
        data: dataPost,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success('mac_address '+ rowData.email + ' berhasil di reset!', {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log("berhasil - 2")
        RefreshData()
      } else {
        setError('Tambah Data gagal.');
      }  
    } catch (error) {
      setError('Tambah Data gagal.');
    }
  };

  const handleDelete = async(deletedData) => {
    try {

      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/lisence/delete/' + deletedData.id,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success(`Berhasil menghapus data '${deletedData.email}'`, {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        RefreshData();
      } else {
        setError('Hapus Data gagal.');
      }  
    } catch (error) {
      setError('Hapus Data gagal.');
    }
  };

  const handleAdd = () => {
    navigate('/licence/add')
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    console.log(value);
    _filters['email'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
};

  const renderHeader = () => {
    return (
        <div className="flex justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </span>
        </div>
    );
};

const handleLogout = () => {
  localStorage.removeItem("user")
  navigate('/login');
};

const handleRefresh = () => {
  // navigate('/');
  window.location.reload();
};

const handlePackage = () => {
  navigate('/module');
};

const handleSoftware = () => {
  navigate('/software');
};

// const handleAdvert = () => {
//   navigate('/advertisement');
// };
const reject = () => {
  // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
};

const packageBodyTemplate = (rowData) => {
  return <Tag value={rowData.isTrial} severity={getSeverity(rowData.isTrial)} />;
};

const statusBodyTemplate = (rowData) => {
  return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
};


const actionBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
          <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => handleEdit(rowData.id) } />
          <Button ref={buttonEl} onClick={() => {
              if (rowData.mac_address) {
                setDeleted(rowData)
                setVisibleResetMac(true)
              }
            } 
          } icon="pi pi-replay" className="mr-2" severity="warning"/>
            {/* <Toast ref={toast} /> */}
            <Button ref={buttonEl} onClick={() => {
              setDeleted(rowData)
              setVisible(true)
            } 
          } icon="pi pi-trash" className="mr-2"/>

      </React.Fragment>
  );
};



  return (
    <div className="container mt-5">
       <h1 className="title">Lisensi User</h1>
       {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
      <div className="columns is-centered">
        <div className="column">
          <div className="field is-grouped">
            <div className="control">
              <Button icon="pi pi-plus" className="button is-primary mb-3"
                onClick={() => handleAdd()} label='Tambah Data'></Button>
            </div>
            <div className="control">
              <Button icon="pi pi-refresh" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--cyan-900)'}}
                onClick={() => handleRefresh()} label='Refresh'></Button>
             </div>
             <div className="control">
              <Button icon="pi pi-box" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--cyan-900)'}}
                onClick={() => handleSoftware()} label='Software'></Button>
             </div>
             <div className="control">
              <Button icon="pi pi-th-large" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--cyan-900)'}}
                onClick={() => handlePackage()} label='Paket Modul'></Button>
             </div>
             {/* <div className="control">
              <Button icon="pi pi-ticket" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--indigo-700)'}}
                onClick={() => handleAdvert()} label='Iklan Produk'></Button>
             </div> */}
            <div className="control">
              <Button icon="pi pi-sign-out" className="button has-background-danger has-text-white mb-3"
                onClick={() => handleLogout()} label='keluar'></Button>
             </div>
          </div>
        </div>
      </div>
      <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={"Apakah anda yakin akan menghapus '" + (deleted ? deleted.email : "") +"' ? " }
                header="Konfirmasi" icon="pi pi-exclamation-triangle" accept={() => handleDelete(deleted)} reject={reject} />

      <ConfirmDialog visible={visibleResetMac} onHide={() => setVisibleResetMac(false)} message={"Apakah anda yakin akan mereset mac_address '" + (deleted ? deleted.email : "") +"' ? " }
                header="Konfirmasi" icon="pi pi-exclamation-triangle" accept={() => handleDeleteMac(deleted)} reject={reject} />
      <div className="columns is-centered">
        <div className="column">
          <div className="table-container" style={{ overflowX: 'auto' }}>
            <div className="card">
              <DataTable value={data} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} 
              tableStyle={{ minWidth: '50rem' }}
              filters={filters} 
              filterDisplay="row"
              header={renderHeader()}
              globalFilterFields={['email', 'mac_address', 'status', 'isTrial']}
              >
                  <Column field="id" header="Id" sortable style={{ width: '5%' }}></Column>
                  <Column field="email" header="Email" sortable style={{ width: '20%' }}></Column>
                  <Column field="mac_address" header="Mac Address" sortable style={{ width: '20%' }}></Column>
                  <Column field="expired" header="Expired" sortable style={{ width: '15%' }}></Column>
                  <Column field="softwareId" header="SoftwareId" sortable style={{ width: '5%' }}></Column>
                  <Column field="status" header="Status" sortable style={{ width: '10%' }} body={statusBodyTemplate}></Column>
                  <Column field="isTrial" header="Paket" sortable style={{ width: '10%' }} body={packageBodyTemplate}></Column>
                  <Column field="lastLogin" header="Last Login" sortable style={{ width: '15%' }}></Column>
                  <Column field="totalItems" header="Total Items" sortable style={{ width: '10%' }}></Column>
                  <Column field="totalCustomers" header="Total Customers" sortable style={{ width: '10%' }}></Column>
                  <Column field="totalTransactions" header="Total Trans" sortable style={{ width: '10%' }}></Column>
                  <Column field="lastTransactionValue" header="Last Trans Value" sortable style={{ width: '10%' }}></Column>
                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
